.App {
  text-align: center;
  background-color: #1e1e1e;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  /* background-color: #1e1e1e; */
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  /* font-size: calc(10px + 2vmin);
  color: white; */
  height:220px;
}

.App-body {
  /* background-color: #282c34; */
  /* background-color: #1e1e1e; */
  font-size:20px;
  color: white;
}
.component {
  /* padding-left: 20px; */
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.button {
  display: inline-block;
  padding: 0px 10px;
  border: 2px solid #fff;
  border-radius: 3px;
  cursor: pointer;
  background-color:rgb(214, 238, 208);
  margin-bottom: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
